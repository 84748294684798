import React from "react"
import Layout from "../../../template/Layout"
import Head from "../../../head";
import {
  Props as BreadcrumbsData,
} from "../../common/Breadcrumbs"
import { ServiceContainer } from "../common/ServiceContainer";
import { SectionTitle } from "../../common/SectionTitle";

import { ServiceContentContainer } from "../common/ServiceContentContainer";
import { MainVisual } from "../common/MainVisual";
import { WhiteContainer } from "../common/WhiteContainer";
import { GrayContainer } from "../common/GrayContainer";
import { Faq } from "../common/Faq";
import { PickUpArticleList } from "../common/PickUpArticleList";
import { ogpUrl } from "../../../utlis/ImageUtil"
import { WPFrontMatter } from "../../../types/WPFrontMatter";
import { EngineerTrainingContent } from "./common/EngineerTrainingContent";
import styled from "styled-components";
import { RESPONSIVE_STYLES } from "../../../AppConst";

import LogoToC from "../../../images/service/logo_01ec_to_c.svg"
import LogoToB from "../../../images/service/logo_01ec_to_b.svg"

import { graphql, useStaticQuery } from "gatsby";

/**
 * Props
 */
type Props = {
  breadcrumbsData: BreadcrumbsData["values"]
  imgMain: any
  imgLogo: any
  imgVoices: Array<any>
  technext: any
  members: Array<any>
  posts: WPFrontMatter[]
  isLoading: boolean
  hasError: boolean
}

/**
 * Component
 */
const ContentsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 160px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 80px;
  }
`

export const ServiceTechnext: React.VFC<Props> = (props) => {
  const data = useStaticQuery(graphql`
    query {
      displayToC: file(relativePath: { eq: "images/service/01ec_to_c_display.png"}) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
      displayToB: file(relativePath: { eq: "images/service/01ec_to_b_display.png"}) {
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      },
    }
  `);

  const CONTENTS = [
    {
      alt: "0→1 ENGINEER CAMP（ゼロイチエンジニアキャンプ） 個人向け｜やる気を掘り起こすプログラミングスクール",
      label: "個人の方はこちら",
      title: "その挑戦が、あなたの人生を変える。",
      description: 'IT業界は常に進化し続けています。一歩踏み出す勇気があれば、新しい世界を見つけるチャンス。未来は無限大です。挑戦を恐れず、成長し、自分の可能性を広げてみませんか？',
      logo: LogoToC,
      img: data.displayToC.childImageSharp.fluid,
      path: '/lp/01engineer-camp-c/',
      className: 'linkService01ECtoC',
    },
    {
      alt: "0→1 ENGINEER CAMP（ゼロイチエンジニアキャンプ）｜実務レベルのエンジニアを育てる超実践型エンジニア育成サービス",
      label: "法人の方はこちら",
      title: "実務レベルのエンジニアを育てる",
      description: '業務遂行で求められる問題解決力や応用力は、実務体験のノウハウを積み上げることでしか身につけられないものです。実務のノウハウを詰め込んだカリキュラムと実務に近い体験を組み合わせ、短期間で即戦力レベルのエンジニアに育成します。',
      logo: LogoToB,
      img: data.displayToB.childImageSharp.fluid,
      path: '/lp/01engineer-camp/',
      className: 'linkService01ECtoB',
    },
  ]

  return (
    <Layout sidebar={false} breadcrumbsData={props.breadcrumbsData}>
      <Head
        title={props.technext.ogp.title}
        description={props.technext.ogp.description}
        img={ogpUrl(process.env.NODE_ENV, props.imgMain)}
      />
      <ServiceContainer paddingBottom={0}>
        <SectionTitle title={props.technext.title.en} subTitle={props.technext.title.ja} />
        <ServiceContentContainer>
          <MainVisual img={props.imgMain} alt={props.technext.title.ja} />
          <WhiteContainer>
            <ContentsWrapper>
              {CONTENTS.map((elem, index) => {
                return (
                  <EngineerTrainingContent
                    key={index}
                    alt={elem.alt}
                    label={elem.label}
                    title={elem.title}
                    description={elem.description}
                    logo={elem.logo}
                    img={elem.img}
                    path={elem.path}
                    className={elem.className}
                  />
                )
              })}
            </ContentsWrapper>
          </WhiteContainer>
          <GrayContainer isBorderBottom={true}>
            <Faq
              title={props.technext.faq.title.ja}
              contents={props.technext.faq.contents}
              url={'/question/01engineer-camp'}
            />
          </GrayContainer>
          <GrayContainer isContinuousGray={true}>
            <PickUpArticleList posts={props.posts} isLoading={props.isLoading} hasError={props.hasError} />
          </GrayContainer>
        </ServiceContentContainer>
      </ServiceContainer>
    </Layout>
  )
}
