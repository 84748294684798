import React from "react"
import styled from "styled-components"

import { RESPONSIVE_STYLES } from "../../../AppConst";
import { ReadMore } from '../../common/ReadMore'
import { graphql, useStaticQuery } from "gatsby";
import { ArticleCard } from "../../index/v2/blog/ArticleCard";
import { ConnectionFailedText } from "../../common/ConnectionFailedText";
import { LoadingAPI } from "../../common/LoadingAPI";

/**
 * Elements & Styles
 */
const Node = styled.div`
  text-align: center;
  & .title--pickup_article {
    margin: 0;

    text-align: center;
    font-family: 'Righteous';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 56px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #222222;
  }
`

const ArticleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 40px;
  margin-bottom: 80px;

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 40px;
    gap: 24px;
  }
`

/**
 * Component
 */
export const PickUpArticleList = ({posts, isLoading, hasError}) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          member {
            name
            filename
            pathname
          }
        }
      }
    }
  `)

  const members = site.siteMetadata.member;

  return (
    <Node>
      <h2 className={"title--pickup_article"}>PICK UP</h2>
      { hasError ? <ConnectionFailedText /> :
          isLoading ? <LoadingAPI /> :
            <>
              <ArticleWrapper>
                {posts.map((post, i) => {
                  const member: { name: string, filename: string } = members.find((m) => m.name == post.author);

                  return (
                    <ArticleCard
                      key={i}
                      frontMatter={post}
                      member={member}
                    />
                  )
                })}
              </ArticleWrapper>
              <ReadMore url='/tags/0→1 ENGINEER CAMP/' darkMode={true} text='記事をもっと見る' />
            </>
      }
    </Node>
  )
}
