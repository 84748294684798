import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { ServiceTechnext } from "../../../components/service/technext"
import imgLogo from "../../../images/logo/01engineercamp_logo_yoko.svg"
import { WPFrontMatter } from "../../../types/WPFrontMatter"
import { OWNEDMEDIA_WORDPRESS_URL } from "../../../AppConst";

const fetch01ECPosts = async (setPosts, setIsLoading, setHasError) => {
  setIsLoading(true);

  fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/pickup_kinds?slug=01ec`)
  .then(response => {return response.json()})
  .then(pickupKindsData => {
    fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/posts?_embed&pickup_kinds=${pickupKindsData[0].id}&per_page=3`)
    .then(response => {return response.json()})
    .then(postsData => {
      const posts: WPFrontMatter[] = [];

      postsData.map((data, i) => {
        const postData: WPFrontMatter = {
          title: data.title.rendered,
          date: data.date,
          category: data._embedded["wp:term"][0][0].slug,
          description: "",
          author: data._embedded.author[0].name,
          thumbnail: data._embedded["wp:featuredmedia"][0].source_url,
          slug: `${data._embedded.author[0].slug}/${data.slug}`
        }
        posts.push(postData);
      })

      setPosts(posts);
      setIsLoading(false);
    })
    .catch((error) => {
      setHasError(true);
    });
  })
  .catch((error) => {
    setHasError(true);
  });
};

export default function ServiceTechnextPage({ data }) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [posts, setPosts] = useState<WPFrontMatter[]>([]);

  useEffect(() => {
    fetch01ECPosts(setPosts, setIsLoading, setHasError);
  }, [])

  const {
    imgMain,
    imgVoice1,
    imgVoice2,
    imgVoice3,
    site: { siteMetadata: { services, member } }
  } = data;

  const breadcrumbsData: { text: string; path: string }[] = [
    { text: services.top.title.en, path: services.top.path },
    { text: services.technext.title.ja, path: services.technext.path },
  ]

  return (
    <ServiceTechnext
      breadcrumbsData={breadcrumbsData}
      imgMain={imgMain.childImageSharp.fluid}
      imgLogo={imgLogo}
      imgVoices={[imgVoice1.childImageSharp.fluid, imgVoice2.childImageSharp.fluid, imgVoice3.childImageSharp.fluid]}
      technext={services.technext}
      posts={posts}
      members={member.filter(m => m.technext > 0).sort((a, b) => {
        if (a.technext < b.technext) return -1;
        if (a.technext > b.technext) return 1;
        return 0;
      })}
      isLoading={isLoading}
      hasError={hasError}
    />
  )
}

export const query = graphql`
  query ServiceTechnextQuery {
    site {
      siteMetadata {
        services {
          top {
            path
            title {
              ja
              en
            }
          }
          technext {
            path
            ogp {
              title
              description
            }
            title {
              ja
              en
            }
            description
            message {
              title
              description
            }
            preferences {
              title {
                ja
                en
              }
              contents {
                title
                descriptions
              }
            }
            curriculum {
              title {
                ja
                en
              }
            }
            flow {
              title {
                ja
                en
              }
            }
            corse {
              title {
                ja
                en
              }
              remarks
            }
            reasons {
              title {
                ja
                en
              }
              contents {
                title
                descriptions
              }
            }
            voice {
              title {
                ja
                en
              }
              contents {
                name
                description
              }
            }
            subsidy {
              title {
                ja
                en
              }
              contents {
                description
                case
                remark
              }
              descriptions
            }
            member {
              title {
                ja
                en
              }
            }
            faq {
              title {
                ja
              }
              contents {
                question
                answer
              }
            }
            pickup {
              url
            }
          }
        }
        member {
          position
          name
          pathname
          alt
          filename
          member_index_filename
          color
          technext
        }
      }
    }
    imgMain: file(relativePath: { eq: "images/service/01ec_thumbnail.JPG"}) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgVoice1: file(relativePath: { eq: "images/service/technext_voice_man.png"}) {
      childImageSharp {
        fluid(maxWidth: 64, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgVoice2: file(relativePath: { eq: "images/service/technext_voice_woman.png"}) {
      childImageSharp {
        fluid(maxWidth: 64, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imgVoice3: file(relativePath: { eq: "images/service/technext_voice_man.png"}) {
      childImageSharp {
        fluid(maxWidth: 64, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
