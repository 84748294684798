import React from "react"
import styled from "styled-components"
import Img, { FluidObject } from "gatsby-image"
import { RoundLinkButton } from "../../../common/RoundLinkButton"
import { RESPONSIVE_STYLES } from "../../../../AppConst"

const Wrapper = styled.div`
  display: flex;
  gap: 40px;
  &:nth-child(2n) {
    flex-direction: row-reverse;
  }
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    flex-direction: column-reverse;
    gap: 24px;
    &:nth-child(2n) {
      flex-direction: column-reverse;
    }
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc((100% - 40px) / 2);
  gap: 32px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
  }

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 24px;
  }
`

const Label = styled.span`
  width: fit-content;
  padding: 8px 16px;
  background-color: #FFFFFF;
  border: 1px solid #222222;

  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: #222222;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 14px;
    line-height: 21px;
  }
`

const LogoWapper = styled.h1`
  display: inherit;
  margin-block: unset;
`

const Logo = styled.img`
  height: auto;
  max-height: 48px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-height: 32px;
  }
`

const Message = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 8px;
  }
`

const Title = styled.h2`
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  text-transform: capitalize;
  color: #222222;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 20px;
    line-height: 30px;
  }
`

const Descritpion = styled.p`
  margin-block: unset;
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0.05em;
  text-align: justified;
  color: #222222;
`

const ImageWrapper = styled.div`
  width: calc((100% - 40px) / 2);
  margin: auto;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    max-width: 560px;
  }
  @media(max-width: ${RESPONSIVE_STYLES.SP_MAX_WIDTH}px) {
    max-width: unset;
  }
`

const MainImage = styled(Img)`
  width: 100%;
  object-fit: cover;
`

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 400px;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: 280px;
  }
`

type Props = {
  alt: string
  label: string
  title: string
  description: string
  logo: any
  img: FluidObject
  path: string
  className: string
}
export const EngineerTrainingContent: React.VFC<Props> = (props) => {
  return (
    <Wrapper>
      <InfoWrapper>
        <Label>{props.label}</Label>
        <LogoWapper>
          <Logo src={props.logo} alt={props.alt} />
        </LogoWapper>
        <Message>
          <Title>{props.title}</Title>
          <Descritpion>{props.description}</Descritpion>
        </Message>
        <ButtonWrapper>
          <RoundLinkButton className={props.className} title={"サイトを見る"} url={props.path} target="_blank" isLp={true} />
        </ButtonWrapper>
      </InfoWrapper>
      <ImageWrapper>
        <MainImage fluid={props.img} alt={props.alt} />
      </ImageWrapper>
    </Wrapper>
  )
}
