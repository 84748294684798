import React from "react"
import styled from "styled-components"
import { RESPONSIVE_STYLES } from "../../../AppConst"
import { TitleAndCaption } from "./TitleAndCaption"
import { ReadMore } from '../../common/ReadMore'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 960px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    max-width: none;
  }
`
const Contents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 96px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-top: 48px;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  box-sizing: border-box;
`
const Question = styled.h4`
  position: relative;
  margin: 0;
  padding: 0 0 0 calc(48px + 16px);
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #222222;
  &:before {
    content: 'Q';
    position: absolute;
    top: -25%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-family: 'Righteous';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.1em;
    background: #61B1C1;
    text-transform: uppercase;
    color: #222222;
    border-radius: 50%;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 0 0 0 calc(30px + 16px);
    font-size: 14px;
    line-height: 28px;
    &:before {
      top: 0;
      width: 30px;
      height: 30px;
      font-size: 16px;
      line-height: 24px;
    }
  }
`
const Answer = styled.p`
  margin: 0;
  padding: 16px;
  margin: 0 0 0 calc(48px + 16px);
  box-sizing: border-box;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  color: #222222;
  background: #FFFFFF;

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin: 0 0 0 calc(30px + 16px);
    font-size: 14px;
    line-height: 28px;
  }

  & > a {
    color: #61B1C1;
    text-decoration-line: underline;
  }
`
const ReadMoreWrapper = styled.div`
  margin-top: 96px;
  text-align: center;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    margin-top: 48px;
  }
`

type Props = {
  title: string
  contents: Array<any>
  url: string
}
export const Faq: React.VFC<Props> = ({title, contents, url}) => {
  return (
    <Wrapper>
      <TitleAndCaption title={title} eng={true} />
      <Contents>
        {
          contents.map((content, i) => {
            return (
              <Content key={i}>
                <Question>{content.question}</Question>
                <Answer dangerouslySetInnerHTML={{ __html: content.answer }} />
              </Content>
            )
          })
        }
      </Contents>
      <ReadMoreWrapper>
        <ReadMore url={url} text='FAQをもっと見る' />
      </ReadMoreWrapper>
    </Wrapper>
  )
}
